@import "./design_system/MediaQueries.scss";
@import "./design_system/Colors.scss";

$tabNavHeight: 3rem;

.MobileTabNav {
  display: none;
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  background-color: white;
  height: $tabNavHeight;
  box-shadow: 0rem -0.05rem 0rem lightgray;
  @include mobile {
    display: flex;
  }

  .TabOption {
    a {
      color: rgba(0, 0, 0, 0.48);
      outline: 0rem;
      border: 0rem;
    }
    .Icon {
      font-size: 1.25rem;
      color: #78909c;
    }
    .TabOption-name {
      margin-top: -0.25rem;
    }
    .active {
      color: $primary;
      .Icon {
        color: $primary;
      }
    }
  }
}
