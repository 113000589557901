@import "../../../assets/styles/design_system/MediaQueries.scss";

.Playlist {
  .Playlist-header {
    @include mobile {
      padding-left: 0.625rem;
    }
  }
  .Playlist-videos {
    background-color: rgba(196, 196, 196, 0.35);
    overflow-y: scroll;
    position: relative;
    display: inline-block;
    @include desktop {
      width: 454px;
      max-height: 76vh !important;
    }
    @include mobile {
      font-size: 0.75rem;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  .gradient {
    // TODO
  }
  .PlaylistItem {
    $itemSpacing: 0.75rem;
    $itemHeight: 5.75rem;
    margin-top: $itemSpacing;
    margin-bottom: $itemSpacing;
    height: $itemHeight;
    box-shadow: 0rem 0.25rem 0.5rem rgba(54, 123, 245, 0.24);
    &.playing {
      box-shadow: 0rem 0.25rem 0.5rem rgba(124, 77, 255, 0.76);
      background-color: rgba(124, 77, 255, 0.08);
    }
    .cover-img {
      height: 100%;
      overflow: inherit;
      .cover {
        overflow: hidden;
        width: 135/80 * $itemHeight;
        height: 100%;
        object-fit: cover;
        .blurred {
          overflow: hidden;
          filter: blur(0.75rem);
          -webkit-filter: blur(0.75rem);
        }
        &.spoiler-guard {
          font-size: 1rem !important;
        }
      }
    }
    .preview-info {
      align-items: space-between;
      justify-content: space-around;
      width: 100% !important;
    }
    &:hover {
      .preview-info {
        background-color: var(--playlist-item-hover-bg);
        &.info-playing {
          background-color: rgba(124, 77, 255, 0.08);
        }
      }
    }
  }
}
