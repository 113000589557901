// SLIDES START
$slideOutDistance: -100%;
$slideInDistance: -50%;
@mixin slide($keyframe, $translate, $animation) {
  transform: $translate;
  -webkit-transform: $translate;
  @keyframes keyframe {
    100% {
      transform: $animation;
    }
  }
  animation: keyframe 0.3s forwards;
}
// SLIDES END

.animated {
  &.slide-right {
    @include slide(slide-in, translateX($slideInDistance), translateX(0%));
  }
  &.slide-up {
    @include slide(slide-up, translateY(-$slideInDistance), translateY(0%));
  }
  &.slide-left {
    @include slide(slide-in, translateX(-$slideInDistance), translateX(0%));
  }
  &.slide-down {
    @include slide(slide-in, translateY($slideInDistance), translateY(0%));
  }
}
