@import "../../assets/styles/design_system/MediaQueries.scss";
@import "../../assets/styles/design_system/Colors.scss";

$avatarSize: 44px;
$navbarHeight: 4.4rem;
$logoHeight: 2.25rem;
$logoHeightMobile: 1.8rem;
$mobileNavHeight: 3.1rem;

nav {
  background-color: white;
  background-color: var(--navBarBg);
  position: fixed;
  top: 0;
  height: $navbarHeight;
  width: 100%;
  padding-left: 1.25rem;
  box-shadow: 0px 2px 4px rgba(54, 123, 245, 0.24);
  @include mobile {
    height: $mobileNavHeight;
    padding-left: 0px;
  }
}

.Navbar {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    flex: 1;
    a {
      text-decoration: none;
    }
  }
  img.logo {
    height: $logoHeight;
    margin-right: 0.5rem;
    padding-top: 0.25rem;
    @include mobile {
      height: $logoHeightMobile;
      margin-left: 0.5rem;
    }
    cursor: pointer;
  }
  .site-title {
    font-size: 28px;
    font-weight: bold;
    color: var(--dark);
    font-family: "Quicksand", sans-serif;
    display: none;
    @include desktop {
      display: block;
    }
  }

  .Searchbar {
    height: 46px;
    position: relative;
    max-width: 40rem;
    flex: 2;

    .Searchbar-results {
      position: absolute;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      top: 41px;
      left: 0px;
      right: 0px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: white;
      @include mobile {
        position: fixed;
        left: 0px;
        right: 0px;
        top: 3.1rem;
        border-top: 0.1px solid rgba(0, 0, 0, 0.25);
      }
    }
    .Searchbar-result-item {
      cursor: pointer;
      padding: 14px;
      z-index: 1;
      &:hover {
        background-color: var(--light-600);
        // border-radius: 6px;
      }
    }
  }
  .right {
    padding-right: 30px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    //
    align-items: center;
    @include mobile {
      padding-right: 5px;
    }
    .notif-nav-item {
      display: flex;
      cursor: pointer;
      margin-right: 18px;
      ion-icon,
      .notifications-outline {
        font-size: 30px;
        color: #78909c;
      }
      .Badge {
        position: relative;
        top: 19px;
        right: 0.625rem;
      }
    }
    .AvatarDropdown {
      &.no-user {
        .Badge {
          cursor: pointer;
          $size: $avatarSize;
          height: $size;
          width: $size;
          border-radius: $size/2;
          font-size: $size/1.28;
          @include mobile {
            width: $avatarSize / 1.3;
            height: $avatarSize / 1.3;
            border-radius: $avatarSize/2 / 1.3;
            font-size: $avatarSize/2;
            align-items: center;
          }
          &:hover {
            box-shadow: 0 0 0 2px $primary;
          }
        }
      }
    }
    .Nav-avatar {
      width: $avatarSize;
      height: $avatarSize;
      border-radius: $avatarSize/2;

      @include mobile {
        width: $avatarSize / 1.3;
        height: $avatarSize / 1.3;
        border-radius: $avatarSize / 2 / 1.3;
        .Icon {
          font-size: 1.5rem;
        }
      }

      object-fit: cover;
      cursor: pointer;
      .Icon {
        // no user photo
        padding-top: 5px;
      }

      &:hover {
        box-shadow: 0 0 0 2px $primary;
      }
      &.bg-primary {
        &:hover {
          box-shadow: 0 0 0 2px lighten($color: $primary, $amount: 10%);
        }
      }
    }
    button {
      min-width: 80px;
    }
  }
  .menu-outline {
    ion-icon {
      font-size: 50px;
      color: #78909c;
      cursor: pointer;
    }
  }
  .Input {
    z-index: 2;
    height: 40px;
    font-size: 0.75rem;
  }
  .Nav-disc-icon {
    @media (min-width: 0px) and (max-width: 350px) {
      display: none;
    }
    @include mobile {
      width: $avatarSize / 1.25;
      height: $avatarSize / 1.25;
    }
  }
}
