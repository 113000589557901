@import "./Colors.scss";
@import "./MediaQueries.scss";

.Divider {
  width: 90%;
  background-color: var(--grey-darkable);
  height: 0.05rem;
  margin: 0.25rem 0 0.25rem;
  &.vertical {
    width: 0.05rem;
    height: auto;
  }
}

.OnClickOutside {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation-name: opacityFadeIn;
  animation-duration: 0.3s;
  &.no-animation {
    animation-duration: 0s;
  }
  &.invis {
    background-color: rgba(0, 0, 0, 0);
    cursor: default;
    z-index: 0;
  }
}

@keyframes opacityFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.StickyTop {
  position: -webkit-sticky;
  position: sticky;
  top: -0.05rem;
  z-index: 1;
}

@mixin circle($size) {
  height: $size;
  width: $size;
  border-radius: $size/2;
  font-size: $size/1.28;
}

.Circle {
  @include circle(1.25rem);
}

.Circle-lg {
  @include circle(2rem);
}

@mixin badge($size) {
  @include circle($size);
  @include circle(1.25rem);
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: white;
  &.Badge-sm {
    @include circle($size * 0.75);
  }
  &.Badge-lg {
    @include circle($size * 1.25);
  }
  &.Badge-xl {
    @include circle($size * 1.75);
  }
}

.Badge {
  @include badge(1.25rem);
}
.Badge-lg {
  @include badge(2rem);
}
.Badge-xl {
  @include badge(2.5rem);
}

.hidden {
  visibility: hidden;
}
.overflow-hidden {
  overflow: hidden;
}

.Menu {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  max-width: 250px;
  .Menu-header {
    cursor: default;
    padding: 0.5rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Divider {
    width: 100%;
  }
  .Menu-item {
    padding: 0.5rem 1.25rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    white-space: nowrap;
    max-width: 11rem;
    min-width: 7.5rem;
    &:hover {
      .Icon {
        color: white;
      }
      background-color: $primary;
      color: white;
      border-radius: 0.25rem;
    }
    .Icon {
      font-size: 1.25rem;
    }
  }
}

@mixin Spinner($outerSize) {
  display: inline-block;
  position: relative;
  width: $outerSize;
  height: $outerSize;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    $innerSize: $outerSize / 1.25;
    width: $innerSize;
    height: $innerSize;
    margin: $outerSize/10;
    border: $outerSize/10 solid $primary;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.Spinner-xl {
  @include Spinner(5rem);
}
.Spinner-lg {
  @include Spinner(2.5rem);
}
.Spinner {
  @include Spinner(1.5rem);
}
.Spinner-md {
  @include Spinner(1rem);
}
.Spinner-sm {
  @include Spinner(0.5rem);
}
