@import "../../../assets/styles/design_system/Colors.scss";

.ImageInput {
  .image-upload {
    width: 6rem;
    height: 5rem;
    min-height: 3rem;
    border-radius: 0.25rem;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: darken($color: $light, $amount: 3%);
    }
  }
  img {
    width: 6rem;
    height: 5rem;
    object-fit: cover;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
}
