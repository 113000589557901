.ActivatedInput {
  .grow {
    .Input {
      animation: grow 0.5s;
      width: 200px;
    }
    input {
      animation: grow 0s;
    }
  }
}

@keyframes grow {
  0% {
    width: 50px;
  }
  100% {
    width: 200px;
  }
}
