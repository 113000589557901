@import "../../assets/styles/design_system/MediaQueries.scss";

.HomeFeed {
  z-index: -1;
  padding: 2rem;
  padding-left: 2.5rem;
  padding-top: 1rem;
  .content {
    padding-top: 0px !important;
  }
  @include mobile {
    padding: 0rem;
  }

  .PortraitCards {
    .flex-wrap {
      height: 19rem;
      overflow-y: hidden;
      margin-bottom: 1.5rem;
    }
    .PortraitCard {
      min-width: 11.75rem;
      box-shadow: var(--card-shadow);
      border-radius: 0.5rem;
      &:hover {
        box-shadow: 0.25rem 0.5rem 1rem rgba(54, 123, 245, 0.24);
      }
      img {
        border-radius: 0.5rem;
        height: 16rem;
      }
      .PortraitCard-name {
        padding-bottom: 0.25rem;
      }
    }
  }
}
