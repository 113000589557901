// @import most specific stylesheet last, eg:
// class='margin-small Divider' should inherit margin-small
@import "./MediaQueries.scss";
@import "../../../components/Navbar/Navbar.scss";
@import "./Button.scss";
@import "./Components.scss";
@import "./Text.scss";
@import "./Spacing.scss";
@import "./Flexbox.scss";
@import "./Animations.scss";

html {
  font-size: 100%; // === 16px
}

body {
  margin: 0;
}
a {
  text-decoration: none;
  &:visited {
    text-decoration: none;
    color: -internal-root-color;
  }
}

.AppContent {
  overflow: none;
  margin-top: $navbarHeight;
  // navbar^
  @include mobile {
    margin-top: $mobileNavHeight;
  }

  background-color: #fafafa;
  min-height: 100%;
}

::-webkit-scrollbar {
  width: 0.6rem;
  @include mobile {
    width: 0.12rem;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

input {
  height: 1.5rem;
}
input[type="radio"] {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 0.05rem solid #dddddd;
  text-align: left;
  padding: 0.5rem;
}
