@import "../../assets/styles/design_system/MediaQueries.scss";

%profilepic {
  height: 16rem !important;
  width: 16rem !important;
  @include mobile {
    width: 8rem !important;
    height: 8rem !important;
  }
  border-radius: 0.5rem;
}

.PlayerProfileScreen {
  .Profile-header {
    .Profile-image {
      @extend %profilepic;
      object-fit: cover;
    }
  }

  .ImageInput {
    @extend %profilepic;
    .image-upload {
      @extend %profilepic;
      .Icon {
        ion-icon {
          font-size: 2rem;
          @include mobile {
            font-size: 1.5rem;
          }
        }
      }
    }
    img {
      @extend %profilepic;
    }
  }
}
