@import "../../../assets/styles/design_system/MediaQueries.scss";

.VideoSection {
  @include mobile {
    h2 {
      margin-left: 0.25rem;
    }
    margin-top: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .EventCard {
      width: 100vw;
      margin-right: 0rem;
      margin-bottom: 0.75rem;
    }
  }
}
