@import "../../assets/styles/design_system/Colors.scss";
@import "../../assets/styles/design_system/MediaQueries.scss";

$margin: 80px;
.VideoScreen {
  display: grid;
  grid-row-gap: 0px;
  grid-column-gap: 3vw;
  margin-top: $margin/2;
  margin-left: $margin/2;
  margin-right: $margin/2;
  @include mobile {
    grid-template-columns: 10fr;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }
  @include desktop {
    margin-left: 3vw;
    margin-right: 3.2vw;
    grid-template-columns: 70fr 30fr;
  }
  .video-container {
    width: 100%;
    @include mobile {
      width: 100%;
    }
  }
  .VideoInfo {
    @include desktop {
      grid-column: 1;
    }
    @include mobile {
      font-size: 0.75rem;
    }
    .VideoInfo-header {
      h3 {
      }
      @include mobile {
        flex-direction: column;
        .share-btn {
          margin-top: 0.625rem;
        }
      }
    }
    .VideoInfo-icon-row {
      font-weight: bold;
      img {
        height: 35px;
      }
      a {
        color: #333333;
      }
      @include mobile {
        .VideoInfo-icon-body {
          flex-direction: column;
        }
        .game-and-follow {
          padding-left: 0.625rem;
          padding-right: 0.625rem;
          justify-content: space-between;
          width: 100%;
        }
        .VideoInfo-uploader {
          align-self: flex-start;
          margin-top: 15px;
        }
      }
    }
    .VideoInfo-uploader {
      cursor: pointer;
    }
  }
  .VideoPlayer {
    position: relative;
    .youtube-controls {
      position: absolute;
      right: 15px;
      bottom: 15px;
      z-index: 1;
      &.showing {
        bottom: 55px;
      }
      &.hiding {
        border-color: $youtube;
      }
    }
  }

  .playlist-container {
    @include desktop {
      grid-column: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    width: 100%;
  }
}

.yt-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  @include mobile {
    top: 0px;
    padding-top: 0px;
    left: 0px;
    right: 0px;
  }
}
.yt-container iframe,
.yt-container object,
.yt-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
