@import "../../../assets/styles/design_system/MediaQueries.scss";
@import "../../../assets/styles/AppStyles.scss";
@import "../../Navbar/Navbar.scss";
@import "../../../assets/styles/design_system/Colors.scss";

.Screen {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .content {
    z-index: -1;
    overflow-y: auto !important;
    position: fixed;
    left: 236px;
    bottom: 0rem;
    top: $navbarHeight;
    right: 0rem;

    @include mobile {
      left: 0rem;
      top: $mobileNavHeight;
      padding-bottom: $tabNavHeight;
    }

    &.no-side-menu {
      left: 0rem;
    }
  }
}
