@import "../../../assets/styles/design_system/MediaQueries.scss";

.FollowGameModal {
  $width: 370px;

  .Modal-container {
    width: $width !important;
  }
  .Modal {
    width: $width !important;
    .Input {
      width: 100%;
    }
    @include mobile {
      width: 90% !important;
      .Input {
        width: 100%;
      }
    }
  }
  .content {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .FollowGameModal-results {
  }
}
