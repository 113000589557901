@import "../../../assets/styles/design_system/MediaQueries.scss";
@import "../../../assets/styles/design_system/Colors.scss";

$inactiveShadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
$activeShadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
$activeBg: white;
$disabledBg: darken(#f6f6f6, 14);

%input-styles {
  outline: 0rem;
  border: 0rem;
  // keeps getting overridden by user agent stylesheet
  background-color: var(--input-inactive);
  color: #828282;
  padding-left: 0.25rem;
  font-size: 1rem;
  width: 100%;
  border: 0rem;
  outline: 0rem;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  @extend %input-styles;
}

.Input {
  &.disabled {
    background-color: $disabledBg;
    input {
      background-color: $disabledBg;
    }
  }

  input {
    @extend %input-styles;
  }
  textarea {
    @extend %input-styles;
    resize: none;
  }
  .search-outline {
    ion-icon {
      font-size: 1.5rem;
      color: #78909c;
    }
  }
  &.focused {
    box-shadow: $activeShadow;
    animation: shadowChange 1.2s;
    animation: colorChange 1.2s;
    background-color: $activeBg;
    input {
      animation: colorChange 1.2s;
      background-color: $activeBg;
    }
    textarea {
      animation: colorChange 1.2s;
      background-color: $activeBg;
    }
  }
  box-shadow: $inactiveShadow;
  &.w-1-1 {
    width: 100%;
  }
  &.w-2-3 {
    width: 66.6%;
  }
  &.w-3-4 {
    width: 75%;
  }
  border-radius: 0.3rem;
  background-color: var(--input-inactive);
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  cursor: text;
  height: 3rem;

  &.multiline {
    height: 12.5rem;
  }

  &.dark-mode {
    // background-color: var(--input-inactive)Dark;
    background-color: var(--input-inactive);

    color: white;
    input {
      // background-color: var(--input-inactive)Dark;
      background-color: var(--input-inactive);
    }
    &.focused {
      animation: colorChangeDark 1.2s;
      // background-color: $activeBgDark;
      background-color: var(--input-active);

      input {
        animation: colorChangeDark 1.2s;
        background-color: var(--input-active);
      }
      textarea {
        animation: colorChangeDark 1.2s;
        background-color: var(--input-active);
      }
    }
  }
}

@keyframes colorChange {
  from {
    background-color: var(--input-inactive);
  }
  to {
    background-color: $activeBg;
  }
}

@keyframes colorChangeDark {
  from {
    background-color: var(--input-inactive);
  }
  to {
    background-color: var(--input-active);
  }
}

@keyframes shadowChange {
  from {
    box-shadow: $inactiveShadow;
  }
  to {
    box-shadow: $activeShadow;
  }
}
