@import "../../../assets/styles/design_system/MediaQueries.scss";
@import "../../../assets/styles/design_system/Colors.scss";

.FollowedGamesList {
  a {
    color: var(--dark);
  }

  ul {
    list-style-type: none;
    padding-left: 0rem;
  }
  @include mobile {
    ul {
      padding-right: 2rem;
    }
    li {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      border-radius: 0.25rem;
    }
    li:nth-child(even) {
      background-color: darken(rgb(250, 250, 250), 4);
    }
  }

  .FollowedListItem {
    max-width: 13.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    &.history-item {
      overflow: hidden;
      .history-item-text {
        width: 13rem;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 1rem;
        margin-left: 0.5rem;
      }
    }

    &.active-game {
      background-color: lighten($color: rgba(198, 205, 218, 0.34), $amount: 0);
    }
    &:hover {
      cursor: pointer;
      background-color: darken($color: rgba(198, 205, 218, 0.34), $amount: 10);
      @include mobile {
        background-color: inherit;
      }
    }
  }
}
