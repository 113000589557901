@import "../../assets/styles/design_system/MediaQueries.scss";
@import "../../assets/styles/design_system/Colors.scss";

$menuWidth: 14.75rem;
$padding: 1rem;

.SideMenu {
  background-color: var(--sideMenuBg);
  color: var(--light);
  height: 100vh;
  width: $menuWidth - $padding;
  position: fixed;
  font-weight: 500;
  padding-right: $padding;
  .scrollbox {
    height: 100vh;
    overflow-y: scroll;
    visibility: hidden;
    margin-right: -10px;
    padding-top: $padding;
  }
  .scrollbox-content,
  .scrollbox:hover,
  .scrollbox:focus {
    visibility: visible;
  }
  .header {
    margin-top: 0.25rem;
    padding-left: $padding;
  }
  a {
    text-decoration: none;
    // color: #333333;
  }
  .Divider {
    width: 95%;
    margin-top: 0.75rem;
  }
  @include mobile {
    display: none;
  }
  .KeepWatching {
    margin-bottom: 7rem;
  }
}

.tourney-notif {
  display: flex;
  .Badge {
    position: relative;
    bottom: -0.75rem;
    right: 0.5rem;
  }
  .Icon {
    font-size: 1.25rem;
  }
}
