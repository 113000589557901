.non-highlightable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

body {
  font-family: "Quicksand", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%header {
  font-weight: normal;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
h1 {
  @extend %header;
  font-size: xlFont;
}
h2 {
  @extend %header;
  font-size: 1.75rem;
}
h3 {
  @extend %header;
  font-size: 1.4rem;
}
h4 {
  @extend %header;
  font-size: 1rem;
}

.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-5xl {
  font-size: 3rem;
}
.text-6xl {
  font-size: 4rem;
}
%link-text {
  &:hover {
    text-decoration: underline;
  }
  color: $primary;
  cursor: pointer;
}

.link {
  @extend %link-text;
}
.link-disabled {
  @extend %link-text;
  color: $info;
  cursor: default;
}
.link-dark {
  @extend %link-text;
  color: $dark;
}

// MISC
.text-bold {
  font-weight: bold;
}
.text-underline {
  text-decoration: underline;
}
.text-italic {
  font-style: italic;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.hover\:underline {
  &:hover {
    text-decoration: underline;
  }
}
