.AddChannelsModal {
  .ChannelResultItem {
    &:hover {
      background-color: darken(white, 5);
    }
  }

  .channel-edit-option {
    .Icon {
      visibility: hidden;
    }
    &:hover {
      background-color: darken(white, 5);
      .Icon {
        visibility: visible;
      }
    }
  }
}
