.mobile-padding {
  @include mobile {
    padding: 1.25rem;
  }
}
// DISPLAY
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}

// BORDER RADIUS START
.border-radius {
  border-radius: 0.5rem;
}
.border-radius-sm {
  border-radius: 0.25rem;
}
.border-radius-lg {
  border-radius: 0.75rem;
}
.border-radius-xl {
  border-radius: 1rem;
}
// BORDER RADIUS END

// TAILWINDS SYNTAX --- START

// START WIDTH
.w-0 {
  width: 0;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.w-10 {
  width: 2.5rem;
}
.w-12 {
  width: 3rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-32 {
  width: 8rem;
}
.w-40 {
  width: 10rem;
}
.w-48 {
  width: 12rem;
}
.w-56 {
  width: 14rem;
}
.w-64 {
  width: 16rem;
}
.w-128 {
  width: 32rem;
}
.w-auto {
  width: auto;
}
.w-px {
  width: 1px;
}
.w-1-1 {
  width: 100%;
}
.w-1-2 {
  width: 50%;
}
.w-1-3 {
  width: 33.333333%;
}
.w-2-3 {
  width: 66.666667%;
}
.w-1-4 {
  width: 25%;
}
.w-2-4 {
  width: 50%;
}
.w-3-4 {
  width: 75%;
}
.w-1-5 {
  width: 20%;
}
.w-2-5 {
  width: 40%;
}
.w-3-5 {
  width: 60%;
}
.w-1-6 {
  width: 16.666667%;
}
.w-2-6 {
  width: 33.333333%;
}
.w-3-6 {
  width: 50%;
}
.w-4-6 {
  width: 66.666667%;
}
.w-5-6 {
  width: 83.333333%;
}
.w-1-12 {
  width: 8.333333%;
}
.w-2-12 {
  width: 16.666667%;
}
.w-3-12 {
  width: 25%;
}
.w-4-12 {
  width: 33.333333%;
}
.w-5-12 {
  width: 41.666667%;
}
.w-4-5 {
  width: 80%;
}
.w-6-12 {
  width: 50%;
}
.w-7-12 {
  width: 58.333333%;
}
.w-8-12 {
  width: 66.666667%;
}
.w-9-12 {
  width: 75%;
}
.w-10-12 {
  width: 83.333333%;
}
.w-11-12 {
  width: 91.666667%;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
// END WIDTH

// START HEIGHT
.h-0 {
  height: 0;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-8 {
  height: 2rem;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-32 {
  height: 8rem;
}
.h-40 {
  height: 10rem;
}
.h-48 {
  height: 12rem;
}
.h-56 {
  height: 14rem;
}
.h-64 {
  height: 16rem;
}
.h-auto {
  height: auto;
}
.h-px {
  height: 1px;
}
.h-full {
  height: 100%;
}
.h-1-1 {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
// END HEIGHT

// START MARGIN
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-8 {
  margin: 2rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-12 {
  margin: 3rem;
}
.m-16 {
  margin: 4rem;
}
.m-20 {
  margin: 5rem;
}
.m-24 {
  margin: 6rem;
}
.m-32 {
  margin: 8rem;
}
.m-40 {
  margin: 10rem;
}
.m-48 {
  margin: 12rem;
}
.m-56 {
  margin: 14rem;
}
.m-64 {
  margin: 16rem;
}
.m-auto {
  margin: auto;
}
.m-px {
  margin: 1px;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}
.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}
.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}
.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}
.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}
.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-24 {
  margin-top: 6rem;
}
.mt-32 {
  margin-top: 8rem;
}
.mt-40 {
  margin-top: 10rem;
}
.mt-48 {
  margin-top: 12rem;
}
.mt-56 {
  margin-top: 14rem;
}
.mt-64 {
  margin-top: 16rem;
}
.mt-auto {
  margin-top: auto;
}
.mt-px {
  margin-top: 1px;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mr-16 {
  margin-right: 4rem;
}
.mr-20 {
  margin-right: 5rem;
}
.mr-24 {
  margin-right: 6rem;
}
.mr-32 {
  margin-right: 8rem;
}
.mr-40 {
  margin-right: 10rem;
}
.mr-48 {
  margin-right: 12rem;
}
.mr-56 {
  margin-right: 14rem;
}
.mr-64 {
  margin-right: 16rem;
}
.mr-auto {
  margin-right: auto;
}
.mr-px {
  margin-right: 1px;
}
.-mr-px {
  margin-right: -1px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mb-24 {
  margin-bottom: 6rem;
}
.mb-32 {
  margin-bottom: 8rem;
}
.mb-40 {
  margin-bottom: 10rem;
}
.mb-48 {
  margin-bottom: 12rem;
}
.mb-56 {
  margin-bottom: 14rem;
}
.mb-64 {
  margin-bottom: 16rem;
}
.mb-auto {
  margin-bottom: auto;
}
.mb-px {
  margin-bottom: 1px;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-12 {
  margin-left: 3rem;
}
.ml-16 {
  margin-left: 4rem;
}
.ml-20 {
  margin-left: 5rem;
}
.ml-24 {
  margin-left: 6rem;
}
.ml-32 {
  margin-left: 8rem;
}
.ml-40 {
  margin-left: 10rem;
}
.ml-48 {
  margin-left: 12rem;
}
.ml-56 {
  margin-left: 14rem;
}
.ml-64 {
  margin-left: 16rem;
}
.ml-auto {
  margin-left: auto;
}
.ml-px {
  margin-left: 1px;
}
// END MARGIN

// START PADDING
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-8 {
  padding: 2rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-12 {
  padding: 3rem;
}
.p-16 {
  padding: 4rem;
}
.p-20 {
  padding: 5rem;
}
.p-24 {
  padding: 6rem;
}
.p-32 {
  padding: 8rem;
}
.p-40 {
  padding: 10rem;
}
.p-48 {
  padding: 12rem;
}
.p-56 {
  padding: 14rem;
}
.p-64 {
  padding: 16rem;
}
.p-auto {
  padding: auto;
}
.p-px {
  padding: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}
.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}
.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}
.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}
.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}
.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}
.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}
.px-auto {
  padding-left: auto;
  padding-right: auto;
}
.px-px {
  padding-left: 1px;
  padding-right: 1px;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-20 {
  padding-top: 5rem;
}
.pt-24 {
  padding-top: 6rem;
}
.pt-32 {
  padding-top: 8rem;
}
.pt-40 {
  padding-top: 10rem;
}
.pt-48 {
  padding-top: 12rem;
}
.pt-56 {
  padding-top: 14rem;
}
.pt-64 {
  padding-top: 16rem;
}
.pt-auto {
  padding-top: auto;
}
.pt-px {
  padding-top: 1px;
}

.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pr-16 {
  padding-right: 4rem;
}
.pr-20 {
  padding-right: 5rem;
}
.pr-24 {
  padding-right: 6rem;
}
.pr-32 {
  padding-right: 8rem;
}
.pr-40 {
  padding-right: 10rem;
}
.pr-48 {
  padding-right: 12rem;
}
.pr-56 {
  padding-right: 14rem;
}
.pr-64 {
  padding-right: 16rem;
}
.pr-auto {
  padding-right: auto;
}
.pr-px {
  padding-right: 1px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pb-24 {
  padding-bottom: 6rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.pb-40 {
  padding-bottom: 10rem;
}
.pb-48 {
  padding-bottom: 12rem;
}
.pb-56 {
  padding-bottom: 14rem;
}
.pb-64 {
  padding-bottom: 16rem;
}
.pb-auto {
  padding-bottom: auto;
}
.pb-px {
  padding-bottom: 1px;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-16 {
  padding-left: 4rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pl-24 {
  padding-left: 6rem;
}
.pl-32 {
  padding-left: 8rem;
}
.pl-40 {
  padding-left: 10rem;
}
.pl-48 {
  padding-left: 12rem;
}
.pl-56 {
  padding-left: 14rem;
}
.pl-64 {
  padding-left: 16rem;
}
.pl-auto {
  padding-left: auto;
}
.pl-px {
  padding-left: 1px;
}
// END PADDING
