@import "../../../assets/styles/design_system/Colors.scss";
@import "../../../assets/styles/design_system/MediaQueries.scss";

$width: 18.125rem;

.EventCard {
  width: $width;
  height: 170/210 * $width;
  box-shadow: var(--card-shadow);
  &:hover {
    box-shadow: 0.25rem 0.5rem 1rem rgba(54, 123, 245, 0.24);
  }
  .EventCard-details {
    height: 40/210 * $width;
    .EventCard-info {
      color: #78909c;
      font-size: 0.9375rem;
    }
  }
}

.cover-img {
  position: relative;
  overflow: hidden;
  $imgHeight: 123/210 * $width;
  height: $imgHeight;

  .spoiler-guard {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
    position: absolute;
    top: 0rem;
    color: white;
    display: flex;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .cover {
    height: $imgHeight;
    object-fit: cover;
  }

  .timebox {
    color: white;
    font-weight: bold;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    position: absolute;
    bottom: 0.625rem;
    right: 0.5rem;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .tier {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
  }
}

.blurred {
  filter: blur(1rem);
  -webkit-filter: blur(1rem);
}
