@import "../../../assets/styles/design_system/Colors.scss";

.SearchInput {
  height: 3rem;
  position: relative;

  .SearchInput-results {
    z-index: 1;
    position: absolute;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    top: 3rem;
    left: 0px;
    right: 0px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    background-color: white;
  }
  .SearchInput-result-item {
    cursor: pointer;
    z-index: 1;
    &:hover {
      background-color: darken($color: #f6f6f6, $amount: 5%);
      border-radius: 0.25rem;
    }
    padding-top: 0rem;
    padding-bottom: 0rem;
    line-height: 0.25rem;
    &.focused {
      background-color: lighten($color: $primary, $amount: 28%);
    }
  }
}
