@import "../../../assets/styles/design_system/Colors.scss";

.TabNav {
  height: 100%;
  .tab-option {
    width: auto;
    min-width: 80px;
    max-width: 200px;
    text-align: center;
    cursor: pointer;
    height: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      box-shadow: 0px 1px 0px rgba(8, 35, 48, 0.24);
    }
    p {
      align-self: center;
      line-height: 240%;
    }
    .Icon {
      margin-right: 3px;
      padding-top: 3px;
    }
  }
  .focused {
    border-bottom: 1px solid $primary;
    font-weight: bold;
  }
}
