@import "../../assets/styles/design_system/MediaQueries.scss";

.EventScreen {
  .spoiler-toggle {
    @include mobile {
      margin-top: 0.75rem;
    }
  }
  .Spinner-xl {
    margin-right: 15vw !important;
  }
  @include mobile {
    padding: 0rem;
    .Event-vods {
      padding: 0rem;
    }
  }
}
