@import "../../../assets/styles/design_system//MediaQueries.scss";

$width: 370px;
$mobileWidth: 90%;
.LoginModal {
  .Modal-container {
    width: $width;
    @include mobile {
      width: $mobileWidth;
    }
  }
  .Modal {
    width: $width;
    @include mobile {
      width: $mobileWidth;
    }
    align-items: center;
    justify-content: center;
  }
  button {
    min-width: 300px;
    max-width: 300px;
    @include mobile {
      width: 250px;
      min-width: 250px;
      max-width: 250px;
    }
  }
  .content {
    width: $width;
    @include mobile {
      width: 100%;
    }
    display: flex;
    padding-left: 0px !important;
    padding-right: 0px;
    justify-content: center;
    padding-bottom: 50px;
    .Divider {
      width: 90%;
    }
  }
  .text-lg {
    margin-left: 5px;
    margin-bottom: 1.25rem;
  }
  .Form {
    width: 100%;
    .btn-row {
      width: 100%;
    }
    .Input {
      width: 100%;
      // CHROME AUTOFILL BG, CANT FIGURE OUT HOW TO OVERRIDE
      background-color: rgb(232, 240, 254);
      input {
        background-color: rgb(232, 240, 254);
      }
      &.focused {
        background-color: white;
        input {
          background-color: white;
        }
      }
    }
    .FormField {
      width: 100%;
    }
  }
}
