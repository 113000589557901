@import "../../../assets/styles/design_system/MediaQueries.scss";

.BannerNavScreen {
  .banner {
    height: 18.75rem;
    object-fit: cover;
  }
  .sticky-nav {
    box-shadow: 0px 0.125rem 0.625rem rgba(8, 35, 48, 0.24);
    z-index: 3;
  }
  .BannerNavScreen-content {
    @include mobile {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @include mobile {
    .TabNav {
      display: none;
    }
    .nav-right {
      display: none;
    }
    .StickyTop {
      // override position:sticky;
      position: relative;
    }
  }
}
