@import "../design_system/MediaQueries.scss";

$success: #2fa84f;
$warning: #f3a72e;
$danger: #ea3d2f;
$primary: #367bf5;
$info: #999;
$light: rgb(246, 246, 246);
$lighter: #fefefe;
$dark: #333333;
$grey: grey;
$darkModeBg: #1a1919;

// for these, you have to hard code the values every time,
// you cant try to use variables inside of these
// any time we change a variable, we have to change these
:root,
:root.light-mode {
  --light: rgb(246, 246, 246);
  --light-600: #eeeeee;
  --dark: #333333;
  --sideMenuBg: rgba(198, 205, 218, 0.34);
  --navBarBg: #fff;
  --bg-screen: #fff;
  --bg-white: white;
  --bg-lighter: #fefefe;
  --grey-darkable: rgba(0, 0, 0, 0.2);
  --playlist-item-hover-bg: #f2f2f2;
  --input-inactive: #e5e5e5;
  --input-active: white;
  --card-shadow: 0rem 0.25rem 0.5rem rgba(54, 123, 245, 0.24);
}
@media (prefers-color-scheme: dark) {
  :root {
    --light: #333333;
    --light-600: #2b2b2b;
    --dark: rgb(246, 246, 246);
    --sideMenuBg: #222121;
    --navBarBg: #222121;
    --bg-screen: rgb(26, 25, 25);
    --bg-white: #141414;
    --bg-lighter: #333333;
    --grey-darkable: grey;
    --playlist-item-hover-bg: #2e2e2e;
    --input-inactive: #0d0d0d;
    --input-active: #2e2e2e;
    --card-shadow: 0rem 0.35rem 0.7rem rgba(0, 0, 0, 0.24);
  }
}
:root.dark-mode {
  --light: #333333;
  --light-600: #2b2b2b;
  --dark: rgb(246, 246, 246);
  --sideMenuBg: #222121;
  --navBarBg: #222121;
  --bg-screen: rgb(26, 25, 25);
  --bg-white: #141414;
  --bg-lighter: #333333;
  --grey-darkable: grey;
  --playlist-item-hover-bg: #2e2e2e;
  --input-inactive: #0d0d0d;
  --input-active: #2e2e2e;
  --card-shadow: 0rem 0.35rem 0.7rem rgba(0, 0, 0, 0.24);
}

.bg-success {
  background-color: $success;
}
.bg-warning {
  background-color: $warning;
}
.bg-danger {
  background-color: $danger;
}
.bg-primary {
  background-color: $primary;
}
.bg-info {
  background-color: $info;
}
.success {
  color: $success;
}
.warning {
  color: $warning;
}
.danger {
  color: $danger;
}
.primary {
  color: $primary;
}
.info {
  color: $info;
}
.light {
  color: var(--light);
}
.light-always {
  color: $light;
}
.dark {
  color: var(--dark);
}
.dark-always {
  color: $dark;
}
.lighter {
  color: $lighter;
}
.bg-screen {
  background-color: var(--bg-screen);
}
.bg-light {
  background-color: var(--light);
}
.bg-light-always {
  background-color: $light;
}
.bg-light-600 {
  background-color: var(--light-600);
}
.bg-grey {
  background-color: $grey;
}
.bg-lighter {
  background-color: var(--bg-lighter);
}
.bg-white {
  background-color: var(--bg-white);
}
.white {
  color: white;
}
.grey {
  color: $grey;
}

// SOCIAL
$facebook: #3b5998;
$twitter: #26a7de;
$instagram: #c13584;
$twitch: #6034b2;
$youtube: #ff0000;

.bg-facebook {
  background-color: $facebook;
  border-color: $facebook;
}
.bg-twitter {
  background-color: $twitter;
  border-color: $twitter;
}

.twitch {
  color: $twitch;
}
.youtube {
  color: $youtube;
}
.twitter {
  color: $twitter;
}
.instagram {
  color: $instagram;
}
