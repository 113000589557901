@import "../../../assets/styles/design_system/Colors.scss";
@import "../../../assets/styles/design_system/MediaQueries.scss";

.VerticalMatchList {
  .VerticalMatchList-result-item {
    color: var(--dark);
    .cover-img {
      height: 11rem;
    }
    &:hover {
      cursor: pointer;
      background-color: var(--light);
      border-radius: 0.25rem;
    }
    .class-icon {
      height: 3rem;
      border-bottom: 1.5px solid transparent;
      &.highlighted {
        border-bottom: 1.5px solid $warning;
      }
    }
    @include mobile {
      .players-list {
        margin-top: -0.25rem;
      }
      .cover-img {
        width: 108% !important;
      }
      .class-icon {
        height: 2rem;
      }
    }
  }
}

.class-icon {
  height: 50px;
  border-bottom: 1.5px solid transparent;
  &.highlighted {
    border-bottom: 1.5px solid $warning;
  }
}
