.Dropdown {
  position: relative;
  display: inline-block;
  .Dropdown-container {
    position: absolute;
    right: 1rem;
    top: 2rem;
  }
  .Dropdown-content {
    display: flex;
    background-color: #f9f9f9;
    box-shadow: 0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.2);
    text-align: center;
    overflow: hidden;
    .Menu-item {
      z-index: 1;
    }
  }
}
