@import "../../../assets/styles/design_system/Colors.scss";

.SettingsModal {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  .SocialLogo {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0);
    padding-bottom: 0px;
    &.active {
      border-bottom: 0.1rem solid;
      &.twitch {
        border-color: $twitch;
      }
      &.twitter {
        border-color: $twitter;
      }
      &.instagram {
        border-color: $instagram;
      }
    }
  }
}
