select {
  border-radius: 0.25rem;
  background-color: white;
  font-weight: 500;
  padding-left: 0.25rem;
  min-width: 7.5rem;
  height: 2.5rem;
  font-size: 1rem;
  cursor: pointer !important;
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
    // color: red;
  }
  option {
    font-weight: normal;
    // color: red;
  }
}
