@import "../../../assets/styles/design_system/MediaQueries.scss";

.Modal {
  position: fixed;
  left: 36%;
  right: 36%;
  z-index: 4;
  border-radius: 15px;
  // background-color: #fefefe;
  // background-color: $light;
  margin: 3% auto; /* % from the top and centered */
  border: 1px solid #888;
  box-shadow: 0.25rem 8px 16px rgba(54, 123, 245, 0.5);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  .content {
    // background-color: red;
    padding: 1.25rem;
    padding-top: 0px;
    align-items: center;
    // padding-bottom: 1.25rem;
  }

  @include mobile {
    left: 0.625rem;
    right: 0.625rem;
  }

  .Modal-close {
    span {
      font-size: 2rem;
      color: #818181;
      padding: 0.625rem;
      padding-right: 0.75rem;
      line-height: 1.25rem;
    }
    span:hover {
      cursor: pointer;
      color: darken($color: #818181, $amount: 60);
    }
  }
}

.Modal-container {
  .OnClickOutside {
    z-index: 3;
  }
}
