@import "./Colors.scss";

@mixin colored($color) {
  border-color: $color;
  color: $color;
  &.fill {
    background-color: $color;
  }
}

button {
  border-radius: 0.25rem;
  min-width: 7.5rem;
  height: 2.5rem;
  outline: 0rem;
  border: 0rem;
  color: white;
  cursor: pointer;
  background-color: white;
  color: $primary;
  background: #ffffff;
  font-family: "Quicksand", "Roboto";
  border: 0.05rem solid #367bf5;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-weight: 600;
  &.fill {
    color: white !important;
  }
  @include colored($primary);
  &.success {
    @include colored($success);
  }
  &.warning {
    @include colored($warning);
  }
  &.danger {
    @include colored($danger);
  }
  &.info {
    @include colored($info);
  }
  .Icon {
    font-size: 1.25rem;
  }
  &.icon-left {
    @extend %btn-icon;
    .Icon {
      margin-right: 0.5rem;
    }
  }
  &.icon-right {
    @extend %btn-icon;
    .Icon {
      margin-left: 0.5rem;
    }
  }
  &.icon-btn {
    @extend %btn-icon;
    min-width: 4rem;
  }
}

%btn-icon {
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: center;
  .Icon {
    margin-top: 0.25rem;
  }
}

button:hover {
  filter: brightness(105%);
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0rem 0.25rem 0.5rem rgba(8, 35, 48, 0.24);
}

button:disabled,
button[disabled] {
  @include colored(lighten($info, 20));
  cursor: default;
  &:hover {
    filter: brightness(105%);
    transition: none;
    box-shadow: none;
  }
}
