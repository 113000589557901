@import "../../../assets/styles/design_system/Colors.scss";

$imgWidth: 6rem;
$imgHeight: 5rem;

.Form {
  .FormField {
    .ImageInput {
      .image-upload {
        width: $imgWidth;
        height: $imgHeight;
        min-height: 3rem;
        border-radius: 0.25rem;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: var(--light-600);
        }
      }
      img {
        width: $imgWidth;
        height: $imgHeight;
        object-fit: cover;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
    .Input {
      width: 100%;
      padding: 0rem;
      input {
        padding-left: 0.75rem;
      }
    }
  }
}

.Form-divider {
  width: 90%;
  align-self: center;
}
.Form-label {
  margin-bottom: 5px;
}

.FolderDropzone {
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.25rem;
    border-width: 0.125rem;
    border-radius: 0.125rem;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &.zone-active {
      border-color: $primary;
    }
    &.zone-accept {
      border-color: $success;
    }
    &.zone-reject {
      border-color: $danger;
    }
  }
}
