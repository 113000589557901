.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-stretch {
  display: flex;
  justify-content: stretch;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-middle {
  display: flex;
  align-items: center;
}
.flex-top {
  display: flex;
  align-items: flex-start;
}
.flex-bottom {
  display: flex;
  align-items: flex-end;
}
.flex-align-around {
  display: flex;
  align-items: flex-around;
}
.align-end {
  align-self: flex-end;
}
.align-start {
  align-self: flex-start;
}
.align-stretch {
  align-self: stretch;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
